let cfg = {
  client_id: "6z5rh1g5jia7azv7ika6cpn8in9jyz",
  redirect_uri: "https://twrm.im-in.space/authorized",
  // human readable strings for the home page and API documentation
  messages_expire_after: "2 days",
  channels_expire_after: "30 days",
  sessions_expire_after: "7 days",
  max_buffer_size: "800",
  github_link: "https://github.com/robotty/recent-messages2",
  // used for both the documentation as well as the actual API calls made by the web app. Don't include a trailing slash
  api_base_url: "https://twrm.im-in.space/api/v2",

  service_owner_info:
    "The recent-messages service was created by randers, but this instance is run by Kody (im-in.space).",
  issues_url: "https://github.com/robotty/recent-messages2/issues",
  general_contact_email_rot13: "xql@vz-va.fcnpr",
  // don't include a trailing slash
  repo_url: "https://github.com/robotty/recent-messages2",
  enable_donation_section: false,

  // Remember to update privacy_last_updated_on when updating these!
  privacy_how_do_i_store_your_data:
    "The collected data described above is securely stored at a server hosted " +
    "in Falkenstein/Vogtland, Germany at Hetzner Online GmbH " +
    "(Hetzner Online GmbH, Industriestr. 25, 91710 Gunzenhausen, Germany, Tel. " +
    "+49 (0)9831 505-0, E-Mail info@hetzner.com - " +
    "https://www.hetzner.com/legal/legal-notice?country=de).",
  privacy_contact_email_rot13: "xql@vz-va.fcnpr",
  privacy_last_updated_on: "20 December 2022",
};

export type Config = typeof cfg;

if (process.env.NODE_ENV === "development") {
  cfg = {
    ...cfg,
    client_id: "199iyze11rzmuu05ddxqzix4g8soou",
    redirect_uri: "http://localhost:2790/authorized",
    api_base_url: "http://localhost:2790/api/v2",
  };
}

export default cfg;
